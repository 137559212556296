var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('VDataTable',{attrs:{"fixed-header":"","height":"calc(100vh - 200px)","hideDefaultHeader":"","headers":_vm.headers,"items":_vm.listItems,"loading":_vm.loading,"footer-props":_vm.footer,"options":_vm.options,"server-items-length":_vm.count,"mobile-breakpoint":0},on:{"update:options":function($event){_vm.options=$event},"click:row":function (ref) {
	var id = ref.id;

	return _vm.onDetail(id);
},"update:page":function (page) { return _vm.onPagination({ page: page }); },"update:items-per-page":function (size) { return _vm.onPagination({ size: size }); }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((_vm.headers),function(ref){
var text = ref.text;
var colspan = ref.colspan;
return _c('th',{key:text,staticClass:"text-start",attrs:{"colspan":colspan}},[_vm._v(_vm._s(text))])}),0),_c('FilterRow',{attrs:{"items":_vm.headers},on:{"input":_vm.onPagination}})],1)]},proxy:true},{key:"item.name",fn:function(ref){
var name = ref.item.name;
return [_c('CompactCeil',{attrs:{"text":name}})]}},{key:"item.contractNumber",fn:function(ref){
var contractNumber = ref.item.contractNumber;
return [_c('CompactCeil',{attrs:{"text":contractNumber}})]}},{key:"item.actions",fn:function(ref){
var ref_item = ref.item;
var status = ref_item.status;
var id = ref_item.id;
return [_c('ApprovingButtons',{attrs:{"state":status.value,"pending":_vm.pending,"id":id,"small":""},on:{"submit-decision":_vm.submitDecisionHandler}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }